export const columns = [
{
    title: '序号',
    dataIndex: 'index',
    key: 'index',
    width: 50,
    align:'center',
    scopedSlots: { customRender: 'index'}
},
  {
    title: '所属门店',
    dataIndex: 'storeName',
    key: 'storeName',
    width:150,
    align:'center',
  },
  {
    title: '所属员工',
    dataIndex: 'managerIdName',
    key: 'managerIdName',
    width:150,
    align:'center',
  },
{
    title: '客户姓名',
    dataIndex: 'name',
    key: 'name',
    width:150,
    align:'center',
    ellipsis:true
},
{
    title: '手机号',
    dataIndex: 'phone',
    key: 'phone',
    width:150,
    align:'center',
    ellipsis:true
},
{
    title: '物业地址',
    dataIndex: 'detailedAddress',
    key: 'detailedAddress',
    width:250,
    align:'center'
},
{
    title: '客户类型',
    dataIndex: 'title',
    key: 'title',
    width:100,
    align:'center',
    ellipsis:true
},
{
    title: '客户来源',
    dataIndex: 'customerSource',
    key: 'customerSource',
    width:200,
    align:'center',
    // ellipsis:true
  scopedSlots: {customRender: 'customerSource'}
},
  {
    title: '物业类型',
    dataIndex: 'propertyType',
    key: 'propertyType',
    align:'center',
    width:150,
    scopedSlots: { customRender: 'propertyType'}
  },
{
    title: '订单类型',
    dataIndex: 'orderType',
    key: 'orderType',
    width:100,
    align:'center',
    ellipsis:true,
  scopedSlots: { customRender: 'orderType'}
},
{
    title: '预订单状态',
    dataIndex: 'orderStatus',
    key: 'orderStatus',
    width:100,
    align:'center',
    ellipsis:true,
  scopedSlots: { customRender: 'orderStatus'}
},
  {
    title: '状态',
    dataIndex: 'registrationStatus',
    key: 'registrationStatus',
    width:100,
    align:'registrationStatus',
    ellipsis:true,
    scopedSlots: { customRender: 'registrationStatus'}
  },
  {
    title: '审核状态',
    dataIndex: 'verifyStatus',
    key: 'verifyStatus',
    width:100,
    align:'center',
    scopedSlots: { customRender: 'verifyStatus'}
  },
{
    title: '平台来源',
    dataIndex: 'sourceType',
    key: 'sourceType',
    width:100,
    align:'center',
    ellipsis:true,
    scopedSlots: { customRender: 'sourceType'}
},
{
  title: '创建时间',
  dataIndex: 'createTime',
  key: 'createTime',
  width:150,
  align:'center',
  // ellipsis:true
},
{
    title: '操作日期',
    dataIndex: 'modifyTime',
    key: 'modifyTime',
    width:200,
    align: 'center',
    ellipsis: true
},

]
